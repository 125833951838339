import React from "react";
import { object } from "prop-types";
import { motion } from "framer-motion";

import Image from "./ImageQuerys/RandomImages";
import CtaSecondary from "./Buttons/secondary-cta";
import downloader from "../images/svg/download-icon.svg";

const buttonMotion = {
  rest: {
    opacity: 0,
    height: 0,
    paddingTop: 0,
    ease: "easeOut",
    duration: 0.2,
    type: "tween",
  },
  hover: {
    opacity: 1,
    height: 85,
    paddingTop: 28,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
};

const ProductSector = (props) => {
  const { sector } = props;
  return (
    <div className="mb-10">
      <div className="flex justify-between">
        <p className="font-bold text-[24px] mb-[32px]">{sector.title}</p>
        {/* <CtaSecondary */}
        {/*  download={sector.zip} */}
        {/*  ctaTitle="Download all" */}
        {/*  target={`/${sector.zip}`} */}
        {/* /> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        {sector.images.map((product) => (
          <motion.figure
            className="w-full rounded-md relative mb-0"
            initial="rest"
            whileHover="hover"
            animate="rest"
          >
            <Image
              imageName={product.image}
              className="rounded-md md:min-h-[232px] max-h-[232px]"
            />
            <motion.a
              variants={buttonMotion}
              download={product.image}
              href={`/${product.image}`}
              className="w-full border-none text-center hover:cursor-pointer text-white bg-black rounded-b-md absolute bottom-0 font-bold"
            >
              Download
              <img
                src={downloader}
                alt="chevron icon"
                className="translate-y-[-4px]"
                style={{ margin: "2px 5px 0px" }}
              />
            </motion.a>
          </motion.figure>
        ))}
      </div>
      <div className=" w-full h-[1px] mt-8 bg-black-700 border-black-700" />
    </div>
  );
};
ProductSector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sector: object,
};

ProductSector.defaultProps = {
  sector: {},
};

export default ProductSector;
