import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Image from "../../components/ImageQuerys/HeroImages";

import ogImage from "../../images/global_assets/og-image.png";
import HeadingBuilder from "../../components-v2/Base/HeadingBuilder";
import NewMediaStyles from "./new-media-styles";
import ProductSectors from "../../components/ProductSectors";

const products = [
  {
    title: "Retail",
    images: [
      { name: "Retail A", image: "retail-photo-a.jpg" },
      { name: "Retail B", image: "retail-photo-b.jpg" },
      { name: "Retail C", image: "retail-photo-c.jpg" },
      { name: "Retail D", image: "retail-photo-d.jpg" },
      { name: "Retail E", image: "retail-photo-e.jpg" },
      { name: "Retail F", image: "retail-photo-f.jpg" },
      { name: "Retail G", image: "retail-photo-g.jpg" },
      { name: "Retail H", image: "retail-photo-h.jpg" },
      { name: "Retail I", image: "retail-photo-i.jpg" },
      { name: "Retail J", image: "retail-photo-j.jpg" },
      { name: "Retail K", image: "retail-photo-k.jpg" },
      { name: "Retail L", image: "retail-photo-l.jpg" },
      { name: "Retail M", image: "retail-photo-m.jpg" },
      { name: "Retail N", image: "retail-photo-n.jpg" },
      { name: "Retail O", image: "retail-photo-o.jpg" },
    ],
  },
  {
    title: "Restaurant",
    images: [
      { name: "Restaurant A", image: "rest-photo-a.jpg" },
      { name: "Restaurant B", image: "rest-photo-b.jpg" },
      { name: "Restaurant C", image: "rest-photo-c.jpg" },
      { name: "Restaurant D", image: "rest-photo-d.jpg" },
      { name: "Restaurant E", image: "rest-photo-e.jpg" },
      { name: "Restaurant F", image: "rest-photo-f.jpg" },
      { name: "Restaurant G", image: "rest-photo-g.jpg" },
      { name: "Restaurant H", image: "rest-photo-h.jpg" },
      { name: "Restaurant I", image: "rest-photo-i.jpg" },
      { name: "Restaurant J", image: "rest-photo-j.jpg" },
      { name: "Restaurant K", image: "rest-photo-k.jpg" },
      { name: "Restaurant L", image: "rest-photo-l.jpg" },
      { name: "Restaurant M", image: "rest-photo-m.jpg" },
      { name: "Restaurant N", image: "rest-photo-n.jpg" },
      { name: "Restaurant O", image: "rest-photo-o.jpg" },
      { name: "Restaurant P", image: "rest-photo-p.jpg" },
      { name: "Restaurant Q", image: "rest-photo-q.jpg" },
      { name: "Restaurant R", image: "rest-photo-r.jpg" },
      { name: "Restaurant S", image: "rest-photo-s.jpg" },
      { name: "Restaurant T", image: "rest-photo-t.jpg" },
      { name: "Restaurant U", image: "rest-photo-u.jpg" },
      { name: "Restaurant V", image: "rest-photo-v.jpg" },
      { name: "Restaurant W", image: "rest-photo-w.jpg" },
      { name: "Restaurant X", image: "rest-photo-x.jpg" },
      { name: "Restaurant Y", image: "rest-photo-y.jpg" },
      { name: "Restaurant Z", image: "rest-photo-z.jpg" },
      { name: "Restaurant ZA", image: "rest-photo-za.jpg" },
    ],
  },
  {
    title: "Enterprise",
    images: [
      { name: "Enterprise A", image: "enterprise-photo-a.jpg" },
      { name: "Enterprise B", image: "enterprise-photo-b.jpg" },
      { name: "Enterprise C", image: "enterprise-photo-c.jpg" },
      { name: "Enterprise D", image: "enterprise-photo-d.jpg" },
      { name: "Enterprise E", image: "enterprise-photo-e.jpg" },
      { name: "Enterprise F", image: "enterprise-photo-f.jpg" },
      { name: "Enterprise G", image: "enterprise-photo-g.jpg" },
      { name: "Enterprise H", image: "enterprise-photo-h.jpg" },
      { name: "Enterprise I", image: "enterprise-photo-i.jpg" },
      { name: "Enterprise J", image: "enterprise-photo-j.jpg" },
      { name: "Enterprise K", image: "enterprise-photo-k.jpg" },
      { name: "Enterprise L", image: "enterprise-photo-l.jpg" },
      { name: "Enterprise M", image: "enterprise-photo-m.jpg" },
    ],
  },
];

const Photography = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Media"
        description="Find out the lastest news about SpotOn and contact us for press inqueries"
        image={`https://spoton.com/${ogImage}`}
      />
      <section className="new-media z-0 text-center">
        <HeadingBuilder
          level={1}
          injectionType={1}
          withBlueSymbol
          symbol="."
          className="new-media-title z-20 whitespace-pre pb-4 lg:pb-0"
        >
          Photography
        </HeadingBuilder>

        <Image
          className="absolute force-abs right-0 top-[-75px] lg:top-[-50px] w-[125px] lg:w-[175px]"
          imageName="custom-circle.png"
        />
        <Image
          className="new-media-shadow z-[-10]"
          imageName="fake-shadow.png"
        />
      </section>
      <section className="max-w-[1440px] translate-y-[-80px] px-[16px] lg:px-[80px] xl:px-[160px]">
        {products.map((sect) => (
          <ProductSectors sector={sect} />
        ))}
      </section>
      <NewMediaStyles />
    </Layout>
  );
};

export default Photography;
